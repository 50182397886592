/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_identity_pool_id": "ca-central-1:01610135-e2e0-41b4-b9c3-e79460e950be",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_W7WK1MPbG",
    "aws_user_pools_web_client_id": "7vdsteumvlvegme7qv0ilgrqvl",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://hwl25v3jxi.execute-api.ca-central-1.amazonaws.com/hdsprod",
            "region": "ca-central-1"
        },
        {
            "name": "saveledgerentry",
            "endpoint": "https://gt63xeambf.execute-api.ca-central-1.amazonaws.com/hdsprod",
            "region": "ca-central-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "ca-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "ppetable-hdsprod",
            "region": "ca-central-1"
        },
        {
            "tableName": "locationinfo-hdsprod",
            "region": "ca-central-1"
        },
        {
            "tableName": "ppeledger-hdsprod",
            "region": "ca-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "qwantifyppecartprofile210730-hdsprod",
    "aws_user_files_s3_bucket_region": "ca-central-1",
    "aws_content_delivery_bucket": "qwppedev-hdsprod",
    "aws_content_delivery_bucket_region": "ca-central-1",
    "aws_content_delivery_url": "https://d14p2ol3plpoto.cloudfront.net"
};


export default awsmobile;
